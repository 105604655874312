import React from "react"
import { graphql } from "gatsby";
import Layout from "../components/Layout"
import Section from "../components/Section";
import { ButtonAnchor, ButtonLink } from "../components/Buttons";
import { HeaderH1, SectionH1, SectionH4, UnderlinedAnchor, StyledCard } from "../helpers/styledComponents";
import { Grid, Card, Image } from "semantic-ui-react";
import styled from "styled-components";
import usaTodayImg from "../../static/articles/usa_today.png";
import nyTimesImg from "../../static/articles/nytimes_print.png";

const headerContent = (
  <HeaderH1 style={{fontSize: "2.5rem"}}>Press & Media</HeaderH1>
);

const CardFooter = styled.div`
  &:first-child {
    border-right: 1px solid #ededed;
  }

  width: 50%;
  text-align: center;
  display: inline-block;
  padding: .75rem;
  font-weight: bold;
`;

interface iArticleImgProps {
  src: string;
  to: string;
};

interface iArticleProps {
  title: string;
  author: string;
  linkToOnlineArticle: string;
  outlet: string;
  datePublished: string;
};

const ArticleImgCard: React.FC<iArticleImgProps> = ({ src, to }) => {
  return (
    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
      <StyledCard raised fluid>
        <Image src={src} wrapped ui={false}/>
        <Card.Content>
          <Card.Description textAlign="center">
            <b><a target="_blank" href={to} >Open in Full Screen</a></b>
          </Card.Description>
        </Card.Content>
      </StyledCard>
    </Grid.Column>
  );
};

const ArticleCard: React.FC<iArticleProps> = ({ title, author, linkToOnlineArticle, outlet, datePublished}) => {
  return (
    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
      <StyledCard raised fluid>
        <Card.Content style={{padding: "1.5rem"}}>
          <Card.Header textAlign="center">
            <p style={{ fontSize: "1.5rem", marginBottom: "1.5rem", lineHeight: "1.125" }}>{title}</p>
            <p style={{ fontSize: "1.25rem", marginBottom: "1.5rem", lineHeight: "1.125" }}>-&nbsp;{author}</p>
          </Card.Header>
          <Card.Description textAlign="center">
            <ButtonAnchor mode="dark" href={linkToOnlineArticle} style={{
              fontSize: "1rem", 
              padding: "calc(.5em - 1px) 1em", 
              lineHeight: "1.5",
              height: "2.5em",
              border: "1px solid black"
            }}>
              Read
            </ButtonAnchor>
          </Card.Description>
        </Card.Content>
        <Card.Content style={{padding: "0"}}>
          <CardFooter>{outlet}</CardFooter>
          <CardFooter>{datePublished}</CardFooter>
        </Card.Content>
      </StyledCard>
    </Grid.Column>
  );
};

export default ({ data }) => {
  return (
    <Layout mode="light" headerContent={headerContent} title="Press &amp; Media | Civic Alliance">
      <Section mode="light" relaxedPadding>
        <Grid>
          <ArticleImgCard src={usaTodayImg} to="/articles/usa_today.png" />
          <ArticleImgCard src={nyTimesImg} to="/articles/nytimes_print.png" />
        </Grid>
      </Section>
      <Section mode="light" relaxedPadding>
        <Grid centered>
          {
            data.allContentfulCivicAlliancePressArticle.nodes.map(article => <ArticleCard {...article} key={article.contentful_id}/>)
          }
        </Grid>
      </Section>
      <Section mode="light">
        <SectionH1>Civic Alliance Press Logos</SectionH1>
        <ButtonLink to="/press-logos" mode="dark" center large>Download</ButtonLink>
      </Section>
      <Section mode="light">
        <SectionH1>Media Inquiries</SectionH1>
        <SectionH4>
          For media inquiries, contact us at &nbsp;
          <UnderlinedAnchor mode="dark" href="mailto:press@civicalliance.com">press@civicalliance.com</UnderlinedAnchor>
        </SectionH4>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulCivicAlliancePressArticle(sort: {fields: datePublished, order: DESC}) {
      nodes {
        author
        contentful_id
        datePublished(formatString: "MMM D, YYYY")
        id
        outlet
        linkToOnlineArticle
        title
      }
    }
  }
`;